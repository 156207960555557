<template>
	<div class="sidebar-primary txtcenter selection-assmat" style="background-image: url(https://www.maine-et-loire.fr/typo3conf/ext/ressource_maine-et-loire/img/sidebar-primary-bg-icon.svg); background-position: bottom right; background-repeat: no-repeat; background-size: contain;">
		<h2>Ma sélection</h2>
		<output>
			<span class="fa fa-solid fa-star"></span> <strong>{{ selection.length }} </strong>
			<span v-if="selection.length > 1">&nbsp;assistants maternels</span>
			<span v-else>&nbsp;assistant maternel</span>
		</output>
		<router-link :to="{ name: 'selection' }" class="btn-cta-pill-inverse">Gérer ma sélection</router-link>
	</div>
</template>

<script>
export default {
  name: 'encartSelection',
	computed: {
		selection: function() {
      return this.$store.getters.assmats
    }
  }
}
</script>
