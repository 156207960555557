<template>
  <div class='map' id="mapContainer" :style="{height: getHeight()}"></div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  name: "Map",
  props: ['height','markers'],
  data() {
    return {
      zoom: 13,
      mapContainer: null
    }
  },
  mounted: function() {
    this.setupLeafletMap();
    
    if(this.markers !== null) this.addMarkers()
  },
  methods: {
    setupLeafletMap: function () {  
      this.mapContainer = L.map('mapContainer').setView([47.3914,-0.7320], this.zoom);
      
      L.tileLayer('https://osmtileserver.maine-et-loire.fr/osm/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright" rel="noreferrer noopener">OpenStreetMap</a> | <a href="//maine-et-loire.fr" target="_blank" title="maine-et-loire.fr - Site du Département de Maine-et-Loire (Nouvelle fenêtre)" rel="noreferrer noopener">Département de Maine et Loire</a>',
        maxZoom: 18,
        minZoom: 9
      }).addTo(this.mapContainer);
    },
    getHeight: function() {
      return this.height+'px'
    },
    addMarkers: function() {
      let group = new L.featureGroup(),
      mrkLength = this.markers.length
      
      const LeafIcon = L.Icon.extend({
        options: {
          iconSize:     [88, 92],
          iconAnchor:   [44, 50],
          popupAnchor:  [0, -30]
        }
      }),
      greenIcon = new LeafIcon({iconUrl: require('@/assets/markers/dispo.png')}),
      redIcon = new LeafIcon({iconUrl: require('@/assets/markers/pas-dispo.png')}),
      blackIcon = new LeafIcon({iconUrl: require('@/assets/markers/dispo-non-renseignee.png')}),
      orangeIcon = new LeafIcon({iconUrl: require('@/assets/markers/dispo-bientot.png')})
      
      this.markers.forEach(m => {
        let icon = blackIcon
        
        switch (m.status) {
          case 'aucuneDispo': icon = redIcon; break;
          case 'dispo': icon = greenIcon; break;
          case 'disposFutures': icon = orangeIcon; break;
        }
        
        let marker = L.marker([m.coord.lat, m.coord.lng], {icon: icon})
        marker.bindPopup(m.content)
        marker.addTo(this.mapContainer);
        group.addLayer(marker)
        
        if(mrkLength === 1 ) marker.openPopup();
      })
      this.mapContainer.fitBounds(group.getBounds());
    }
  }
}
</script>

<style>
.map {
  width: 100%
}

.leaflet-popup-content {
  font-size: 1.4rem;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
}
</style>